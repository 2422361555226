import CarouselItem from "./CarouselItem.tsx";
import { useEffect, useState } from "preact/hooks";

export default function Carousel({ images, interval }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const next = () => {
    setCurrentIndex((current) =>
      current === images.length - 1 ? 0 : current + 1
    );
  };

  const prev = () => {
    setCurrentIndex((current) =>
      current === 0 ? images.length - 1 : current - 1
    );
  };

  const pause = () => {
    setIsPaused(true);
  };

  const play = () => {
    setIsPaused(false);
  };

  // Set up auto-rotation interval
  useEffect(() => {
    // Only set interval if one was provided and there are multiple images
    // and the carousel is not paused
    if (interval && images.length > 1 && !isPaused) {
      const timer = setInterval(next, interval);

      // Clear interval on component unmount
      return () => clearInterval(timer);
    }
  }, [interval, images.length, isPaused]);

  function drawImage(item, index) {
    return (
      <div
        key={index}
        class={`carousel-slide ${index === currentIndex ? "active" : ""}`}
      >
        <CarouselItem item={item} />
      </div>
    );
  }

  function drawNav() {
    return (
      <>
        <div class="carousel-button prev" onClick={prev}>«</div>
        <div class="carousel-button next" onClick={next}>»</div>
      </>
    );
  }

  return (
    <div
      class="carousel"
      onMouseEnter={pause}
      onMouseLeave={play}
    >
      <div class="carousel-wrapper">
        {images.map((img, index) => drawImage(img, index))}
      </div>

      {images.length > 1 ? drawNav() : null}
    </div>
  );
}
